import { RefObject, useEffect, useRef, useState } from 'react'

type Props = {
  root?: HTMLElement
  rootMargin?: string
}

type Result<T> = {
  elementRef: RefObject<T>
  intersectionRatio: number
}

// This hook is used to observe an element and determine if there is an intersection with the viewport
// rootMargin is the margin to check for intersection.
// The default value means that the element will be considered intersected when it is 1px away from the top of the viewport.
export default function useElementIntersection<TElement extends HTMLElement>({
  root,
  rootMargin = '-1px 0px 0px 0px',
}: Props): Result<TElement> {
  const elementRef = useRef<TElement>(null)
  const [intersectionRatio, setIntersectionRatio] = useState(-1)

  useEffect(() => {
    const intersectedElement = elementRef.current
    if (!intersectedElement) {
      return
    }

    const observer = new IntersectionObserver(
      ([e]) => {
        setIntersectionRatio(e.intersectionRatio)
      },
      { root, threshold: [0, 1], rootMargin }
    )

    observer.observe(intersectedElement)
    return () => observer.unobserve(intersectedElement)
  }, [elementRef, root, rootMargin])

  return {
    elementRef,
    intersectionRatio, // How much of the element is visible within the viewport (0-1)
  }
}
